import { render } from '@testing-library/react'
import React from 'react'
export default class ContactForm extends React.Component {

    constructor() {
        super()
        this.State = {
            user: null,
            email: null,
            subject: null,
            message: null
        }
    }

    submit() {
        console.warn(this.state)
    }

    render() {
        return (
            <div>
                <div className="at-wrapper at-career-form-wrapper">
                    <div className="container at-career-form-container">
                        <h2 className="at-form-section--heading">
                            Get In <span>Touch</span>
                        </h2>
                        <div className="row at-career-form-row">
                            <div className="at-career-form-col col-lg-6">
                                <div className="at-row at-career--form">
                                    <div className="form-group mb-4 col-md-6">
                                        <img className="at-form--icons" src={`${process.env.PUBLIC_URL}/images/person.png`} alt="" />
                                        <input
                                            type="text"
                                            name="Name" id=""
                                            className="form-control"
                                            placeholder="Your Name"
                                            required=""
                                            onChange={(e) => { this.setState({ user: e.target.value }) }}
                                        />
                                    </div>
                                    <div className="form-group mb-4 col-md-6">
                                        <img className="at-form--icons" src={`${process.env.PUBLIC_URL}/images/email.png`} alt="" />
                                        <input
                                            type="email"
                                            required=""
                                            name="email"
                                            className="form-control"
                                            placeholder="Your email"
                                            onChange={(e) => { this.setState({ email: e.target.value }) }}
                                        />
                                    </div>
                                    <div className="form-group mb-4 col-md-12">
                                        <img className="at-form--icons" src={`${process.env.PUBLIC_URL}/images/write.png`} alt="" />
                                        <input
                                            type="text"
                                            required=""
                                            name="subject"
                                            className="form-control"
                                            placeholder="Your subject here"
                                            onChange={(e) => { this.setState({ subject: e.target.value }) }}
                                        />
                                    </div>

                                    <div className="form-group mb-4 col-md-12 at-textarea">
                                        <img className="at-form--icons" src={`${process.env.PUBLIC_URL}/images/message.png`} alt="" />
                                        <textarea
                                            className="form-control"
                                            placeholder="Your message here"
                                            required=""
                                            name="message"
                                            rows="5"
                                            onChange={(e) => { this.setState({ message: e.target.value }) }}
                                        />
                                    </div>

                                    <div className="form-group col-md-12">
                                        <button className="at-button at-button--background" onClick={() => this.submit()}>
                                            Send message
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className="at-career-form-col col-lg-6">
                                <img src={`${process.env.PUBLIC_URL}/images/image-newsletter.png`} alt="" className="at-form--newsletter-img" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}