import React from 'react'
import './About.css'
import MeetOurTeamTab from './MeetOurTeamTab'

const About = () => {
    return (
        <div className="at-about-us">
            <div id="at-about-us">
                <div className="at-wrapper at-about__banner-wrapper">
                    <div className="container at-about__bg-container">
                        <div className="row at-about-banner__row">
                            <div className="at-about-banner__content col-lg-6">
                                <div className="at-about__inner-content">
                                    <h2 className="at-about__banner--heading">
                                        ABOUT US
                                    </h2>
                                    <h3 className="at-about__banner--text">
                                        Born out of passion for well crafted digital experience. <span>At Atulaey</span> , we’re building industry-changing products and tools empowering people. A team who believe in action , not words .
                                    </h3>
                                </div>
                            </div>
                            <div className="at-about-banner__img col-lg-6">
                                <img src={`${process.env.PUBLIC_URL}/images/about-vector.png`} alt="banner-vector-img" />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="at-wrapper at-about__our-story-wrapper">
                    <div className="container at-our-story--heading-container">
                        <h2 className="at-about__our-story--heading at-section--heading">
                            Our Success <span>Story</span>
                        </h2>
                    </div>
                    <div className="at-our-story__bg">
                        <div className="at-our-stroy__bg--overlay"></div>
                    </div>
                    <div className="at-about__our-story-container container">
                        <div className="row at-about-our-story-row">
                            <div className="at-about-our-story__content-wrap col-lg-6">
                                <div className="at-about-our-story--bg">
                                    <p className="at-about-our-story--text">
                                        When thoughts starts converting to reality , impossible start converting to possibility , we stand there . Emerging from small part of the country and making up the mind to acheive big . From spark in mind to build something new to actually acheiving it is what that makes 'Atulaey' . The Mantra we followed is 'Dreaming big and never give up'. It's not done yet. We keep on moving forward with a great team and building products which will lead to empower people .
                                    </p>
                                    <div className="at-about-our-story__content-overlay"></div>
                                </div>
                            </div>
                            <div className="at-about-our-story-imgs col-lg-6">
                                <img src={`${process.env.PUBLIC_URL}/images/circle-img-1.png`} alt="img-1" className="at-about-our-story-img1" />
                                <img src={`${process.env.PUBLIC_URL}/images/circle-img2.png`} alt="img-2" className="at-about-our-story-img2" />
                            </div>
                        </div>
                    </div>
                </div>

                {/* <MeetOurTeamTab /> */}

                <div className="at-wrapper at-about__business-wrapper">
                    <div className="at-business__bg-container">
                        <div className="at-about-business__row">
                            <div className="at-about-business__img">
                                <img src={`${process.env.PUBLIC_URL}/images/bussiness-img.png`} alt="business-img" />
                            </div>
                            <div className="at-about-business__content">
                                <div className="at-about__business-inner-content">
                                    <h2 className="at-about__business--heading">
                                        Our <span>Business</span> Model
                                    </h2>
                                    <p className="at-about__business--text">
                                        Our Business model consists of <strong>5</strong> major steps , here they are :- <br /><strong>1 )</strong> Analysing what people need . <br /> <strong>2 )</strong> Planning to convert the needs to reality . <br /> <strong>3 )</strong> Implementing the idea to real world . <br /><strong>4 )</strong> Focusing on future scope . <br /><strong>5 )</strong> Continuous upgradation with the changing demand . <br /><br /> We beleive a litle can make a big difference so here we put everything that will lead to continuous growth and impactful services.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="at-about--cta-section at-wrapper">
                    <div className="at-about--cta-container container">
                        <div className="row at-about--cat-row">
                            <div className="at-about-cta--left-section col-md-12">
                                <span className="at-cta--quick-contact">Quick Contact</span><br />
                                <h2 className="at-about--cta--heading">
                                    Want to <span>grow</span> with us ?
                                </h2>
                            </div>
                            <div className="at-cta-dotted-arrow">
                                <img src={`${process.env.PUBLIC_URL}/images/Dotted-line.png`} alt="dotted-line" className="at-dotted-arrow" />
                            </div>
                            <div className="at-about-cta--right-section col-md-12">
                                <a href="/ContactUs" className="at-button at-button--background">
                                    CONTACT US
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}


export default About;