import { Link } from "react-router-dom"
import './Header.css'

const Header = () => {
    return (
        <div className="at-header--wrapper">
            <div className="at-wrapper at-header--inner-wrap">
                <div className="container at-header--container">
                    <nav className="at-navbar navbar navbar-expand-lg at-navbar-expand">
                        <a className="navbar-brand at-header--logo" href="#"><img src="images/logo-colored.svg" alt="white-logo" /></a>
                        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbar" aria-controls="navbar" aria-expanded="false" aria-label="Toggle navigation">
                            <div className="at-hemburger-inner-wrap">
                                <div className="at-header-toggler-bar"></div>
                                <div className="at-header-toggler-bar"></div>
                                <div className="at-header-toggler-bar"></div>
                            </div>
                        </button>
                        <div className="collapse navbar-collapse at-navbar-collapse" id="navbar">
                            <div className="navbar-nav">
                                <Link to={'/'} className="nav-item nav-link at-navlinks">
                                    Home
                                    <span></span>
                                </Link>
                                <Link to={'/about'} className="nav-item nav-link at-navlinks">
                                    About
                                    <span></span>
                                </Link>
                                <Link to={'/career'} className="nav-item nav-link at-navlinks">
                                    Career
                                    <span></span>
                                </Link>
                                <Link to={'/service'} className="nav-item nav-link at-navlinks">
                                    Services
                                    <span></span>
                                </Link>
                                <Link to={'/contactus'} className="nav-item nav-link at-navlinks">
                                    ContactUs
                                    <span></span>
                                </Link>
                            </div>
                        </div>
                    </nav>
                </div>
            </div>
        </div>
    )
}


export default Header;