import React from 'react'
import './Service.css'

const ContactUs = () => {
    return (
        <div>
            <div id="at-services">
                <div class="at-wrapper at-services__banner-wrapper">
                    <div class="at-services__bg-container">
                        <div class="at-services__row">
                            <div class="at-services__content">
                                <div class="at-services-inner-content">
                                    <h2 class="at-services--heading">
                                        OUR SERVICES
                                    </h2>
                                    <h3 class="at-services--text">
                                        Every good company wants to provide excellent customer service, but it’s not always easy to balance that with other <span>business needs and goals. </span> We at Atulaey focus on providing the best quality service and making it better everytime . We analyse the worlds requirements and try to come up with the best product . In the field of technology we have came very far and always try to come up with the latest and trending products .
                                    </h3>
                                </div>
                            </div>
                            <div class="at-services__img">
                                <img src={`${process.env.PUBLIC_URL}/images/service-bg.svg`} alt="" />
                            </div>
                        </div>
                    </div>
                </div>

                <div class="at-wrapper at-know-services-wrapper">
                    <div class="container at-know-services-container">
                        <h2 class="at-know-services--heading">
                            Know Our <span>Services</span>
                        </h2>
                        <div class="row at-know-services-row">
                            <div class="col-lg-6 at-know-services--left">
                                <div class="at-know-services-inner">
                                    <img src={`${process.env.PUBLIC_URL}/images/quotes.svg`} alt="" />
                                    <h3 class="at-know-services--title">
                                        We know where our strengths lie and where we need to leverage additional expertise, so you get the best results.
                                    </h3>
                                </div>
                            </div>

                            <div class="col-lg-6 at-know-services--right">
                                <p class="at-know-services--para">
                                    From your very first interaction with us, everything we do is built around understanding your goals and the results that you really care about. Your goals are our guiding compass, and our collaborative approach means we’re able to identify new opportunities to improve your business.With our whole team backing you, you can’t go wrong.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="at-wrapper at-our-work-wrapper">
                    <div class="at-our-work-container container">
                        <h2 class="at-our-work-section--heading">
                            What We <span>Do</span>
                        </h2>
                        <div class="at-row at-our-work-row">
                            <div class="col-lg-6 at-our-work__listing">
                                <div class="at-our-work__inner-wrap">
                                    <h3 class="at-our-work__title">
                                        Web Development
                                    </h3>
                                    <p class="at-our-work__para">
                                        Whatever field or industry you want your website to be built in, with our qualified web developers and designers you will find web solutions that go beyond your expectations in terms of performance and usability.
                                    </p>
                                </div>
                            </div>
                            <div class="col-lg-6 at-our-work__listing">
                                <div class="at-our-work__inner-wrap">
                                    <h3 class="at-our-work__title">
                                        Product Build
                                    </h3>
                                    <p class="at-our-worh__para">
                                        From thinking to implementing the needs of clients and creating the product from scratch. We deliver what one think.
                                    </p>
                                </div>
                            </div>
                            <div class="col-lg-6 at-our-work__listing">
                                <div class="at-our-work__inner-wrap">
                                    <h3 class="at-our-work__title">
                                        Technical Optimisation
                                    </h3>
                                    <p class="at-our-worh__para">
                                        One of the main aspects of optimisation is SEO optimization in order to rank high in SERPs so that the user can find your brand in the easiest way possible . The UX is also very important for converting the user into lead . At Atulaey we provide all these optimaization .
                                    </p>
                                </div>
                            </div>
                            <div class="col-lg-6 at-our-work__listing">
                                <div class="at-our-work__inner-wrap">
                                    <h3 class="at-our-work__title">
                                        Graphics Designing
                                    </h3>
                                    <p class="at-our-worh__para">
                                        We offer Graphic Design services , the process of creating visual content in form of pictures, illustrations, typography, icons, photos, etc. to communicate messages . Designers create a concept and develop print-friendly designs for product packaging.
                                    </p>
                                </div>
                            </div>
                            <div class="col-lg-6 at-our-work__listing">
                                <div class="at-our-work__inner-wrap">
                                    <h3 class="at-our-work__title">
                                        Software Development
                                    </h3>
                                    <p class="at-our-worh__para">
                                        We build Softwares . The Services are aimed at designing, engineering, deploying, supporting, and evolving various software types in order to match the requirement of the digitalised world to acheive everything within comfort .
                                    </p>
                                </div>
                            </div>
                            <div class="col-lg-6 at-our-work__listing">
                                <div class="at-our-work__inner-wrap">
                                    <h3 class="at-our-work__title">
                                        Application Development
                                    </h3>
                                    <p class="at-our-worh__para">
                                        We provide App Development having great user experience and smooth flow from one screen to other. We focus on what we can build to make it easy for both literate and illiterate to navigate through an application to fulfill their requirement and get the best out of it .
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="at-services--cta-section at-wrapper">
                    <div class="at-services--cta-container container">
                        <div class="row at-services--cat-row">
                            <div class="at-services-cta--left-section col-md-12">
                                <span class="at-cta--quick-contact">Quick Contact</span><br />
                                <h2 class="at-services--cta--heading">
                                    Want to get our <span>services</span> ?
                                </h2>
                            </div>
                            <div class="at-cta-dotted-arrow">
                                <img src={`${process.env.PUBLIC_URL}/images/Dotted-line.png`} alt="dotted-line" class="at-dotted-arrow" />
                            </div>
                            <div class="at-services-cta--right-section col-md-12">
                                <a href="#" class="at-button at-button--background">
                                    GET A CALL
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}


export default ContactUs;