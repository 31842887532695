import { render } from '@testing-library/react'
import React from 'react'
export default class ContactForm extends React.Component {

    constructor() {
        super()
        this.state = {
            firstName: "",
            lastName: "",
            number: "",
            emailId: "",
            interestArea: "",
            message: ""
        }
    }

    submit() {
        console.warn(this.state)
        //this.clearField();
    }
    // clear field 
    clearField() {
        this.setState({
            firstName: "",
            lastName: "",
            number: 0,
            emailId: "",
            interestArea: "",
            message: ""
        })
    }
    // onChange Handler
    changeHandler(e) {
        this.setState({ [e.target.name]: e.target.value })
    }

    render() {
        return (
            <div>
                <div id="contactUs-form" className="at-wrapper at-contact-us-form-wrapper">
                    <div className="at-contact-us-form-container container">
                        <div className="row at-contact-us-form__row">
                            <div className="at-contact-us-form__left col-lg-4">
                                <div className="at-contact-form-inner--content">
                                    <p className="at-contact-form-title">
                                        Fill up the form and our Team will get back to you within
                                        24 hours.
                                    </p>
                                    <p className="at-contact-form-subtitle">
                                        I-99 ,
                                        alpha 2 , Greater Noida India
                                    </p>
                                    <div className="at-email-info-wrap">
                                        <img src={`${process.env.PUBLIC_URL}/images/icon-email.png`} alt="" />
                                        <p className="at-email">
                                            atul@atulaey.com
                                        </p>
                                    </div>
                                    <div className="at-phn-info-wrap">
                                        <img src={`${process.env.PUBLIC_URL}/images/phone.png`} alt="" />
                                        <p className="at-phn">
                                            840-040-8888
                                        </p>
                                    </div>
                                    <a href="#" className="at-button at-button--transparent">
                                        BOOK A MEETING !
                                    </a>

                                    <div className="at-form--social-icons-wrap">
                                        <img src={`${process.env.PUBLIC_URL}/images/fb.png`} alt="" />
                                        <img src={`${process.env.PUBLIC_URL}/images/insta.png`} alt="" />
                                        <img src={`${process.env.PUBLIC_URL}/images/ln.png`} alt="" />
                                    </div>
                                    <img className="at-form--pattern" src={`${process.env.PUBLIC_URL}/images/contact-form-pattern.png`} alt="" />
                                </div>
                            </div>
                            <div className="at-contact-us-form__right col-lg-8">
                                <div className="at-row at-contact-us--form-wrap">

                                    {/* Form section begin */}
                                    <div className="col-md-6 form-group">
                                        <label>First Name</label>
                                        <input
                                            value={this.state.firstName}
                                            type="text"
                                            name="firstName"
                                            className="form-control"
                                            placeholder="First Name"
                                            required=""
                                            onChange={(e) => { this.changeHandler(e) }}
                                        />
                                    </div>
                                    <div className="col-md-6 form-group">
                                        <label>Last Name</label>
                                        <input
                                            type="text"
                                            value={this.state.lastName}
                                            name="lastName"
                                            className="form-control"
                                            placeholder="Last Name"
                                            required=""
                                            onChange={(e) => { this.changeHandler(e) }}
                                        />
                                    </div>
                                    <div className="col-md-6 form-group">
                                        <label>Phone</label>
                                        <input
                                            type="number"
                                            value={this.state.number}
                                            name="number"
                                            className="form-control"
                                            placeholder="Phone Number"
                                            required=""
                                            onChange={(e) => { this.changeHandler(e) }}
                                        />
                                    </div>
                                    <div className="col-md-6 form-group">
                                        <label>Email</label>
                                        <input
                                            type="email"
                                            name="emailId"
                                            value={this.state.emailId}
                                            className="form-control"
                                            placeholder="Email"
                                            required=""
                                            onChange={(e) => { this.changeHandler(e) }}
                                        />
                                    </div>
                                    <div className="col-12 form-group">
                                        <label>I’d love to</label>
                                        <div className="at-your-choice">
                                            <span className="at-form--choice-wrap">
                                                <input
                                                    type="checkbox"
                                                    name="interestArea"
                                                    value="Web Development"
                                                    onChange={(e) => { this.setState({ interestArea: e.target.value }) }}
                                                />
                                                <span className="at-form-contents">Web Development</span>
                                            </span>
                                            <span className="at-form--choice-wrap">
                                                <input
                                                    type="checkbox"
                                                    name="interestArea"
                                                    value="UI/UX Designers"
                                                    onChange={(e) => { this.setState({ interestArea: e.target.value }) }}
                                                />
                                                <span className="at-form-contents">UI/UX Designers</span>
                                            </span>
                                            <span className="at-form--choice-wrap">
                                                <input
                                                    type="checkbox"
                                                    name="interestArea"
                                                    value="Software Development"
                                                    onChange={(e) => { this.setState({ interestArea: e.target.value }) }}
                                                />
                                                <span className="at-form-contents">Software Development</span>
                                            </span>
                                            <span className="at-form--choice-wrap">
                                                <input
                                                    type="checkbox"
                                                    name="interestArea"
                                                    value="App Development"
                                                    onChange={(e) => { this.setState({ interestArea: e.target.value }) }}
                                                />
                                                <span className="at-form-contents">App Development</span>
                                            </span>
                                        </div>
                                    </div>
                                    <div className="col-md-12 form-group">
                                        <label>Message (Optional)</label>
                                        <textarea
                                            value={this.state.message}
                                            name="message"
                                            cols="40"
                                            rows="10"
                                            className="form-control"
                                            placeholder="Write your message ..."
                                            onChange={(e) => { this.changeHandler(e) }}
                                        />
                                    </div>
                                    <div className="at-contact-us-form-btn-wrap">
                                        <button className="at-button at-button--background" onClick={() => this.submit()}>
                                            SEND MESSAGE
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}