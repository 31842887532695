import React from 'react'
import './Career.css'
import ContactForm from './ContactForm'


const Career = () => {
    return (
        <div>
            <div id="at-career">
                <div className="at-wrapper at-career__banner-wrapper">
                    <div className="container at-career__bg-container">
                        <div className="row at-career-banner__row">
                            <div className="at-career-banner__content col-lg-6">
                                <div className="at-career__inner-content">
                                    <h2 className="at-career__banner--heading">
                                        Start your <span>Career</span> with <span>Atulaey</span>
                                    </h2>
                                    <h3 className="at-career__banner--text">
                                        We’re rapidly growing with all of our teams. We look for self-starters with a bias towards action, a thirst for learning, and will for asking the right questions. We strive for Atulaey to be the place where you do your <span>life’s best work.</span>
                                    </h3>
                                    <div className="at-career-button__wrapper">
                                        <a href="/About" className="at-button at-button--background">
                                            MORE ABOUT US
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="at-career-banner__img col-lg-6">
                                <img src={`${process.env.PUBLIC_URL}/images/career-img.png`} alt="career-img" />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="at-wrapper at-career--hiring-wrapper">
                    <div className="container at-career-hiring-container">
                        <h2 className="at-career--heading">
                            We're <span>Hiring</span>
                        </h2>
                        <div className="row at-career-hiring-row">
                            <div className="col-lg-4 at-career--grids at-career-position1">
                                <div className="at-career-inner-bg1">
                                    <div className="at-career-content-wrap">
                                        <h2 className="at-career--profile">
                                            UI / UX
                                        </h2>
                                        <p className="at-career--work">
                                            Designing and developing a UI/UX with easy flow and better user gathering.
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4 at-career--grids at-career-position2">
                                <div className="at-career-inner-bg2">
                                    <div className="at-career-content-wrap">
                                        <h2 className="at-career--profile">
                                            DEVELOPE
                                        </h2>
                                        <p className="at-career--work">
                                            Developing softwares which leads to easy life .
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4 at-career--grids at-career-position3">
                                <div className="at-career-inner-bg3">
                                    <div className="at-career-content-wrap">
                                        <h2 className="at-career--profile">
                                            MARKETING
                                        </h2>
                                        <p className="at-career--work">
                                            A marketing strategy to grow more in world with best outcomes.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="at-wrapper at-career__recruitment-wrapper">
                    <div className="at-career__recruitment-container container">
                        <h2 className="at-recruitment-section--heading">
                            Our Recruitment <span>Process</span>
                        </h2>
                        <div className="row at-carrer__recruitment-row">
                            <div className="at-career__recruitment-col col-md-12">
                                <div className="at-career__recritment--process">
                                    <div className="at-recruitment-img">
                                        <img src={`${process.env.PUBLIC_URL}/images/application.png`} alt="" />
                                    </div>
                                    <div className="at-recruitment--content">
                                        <h3 className="at-recruitment--heading">
                                            1 ) APPLICATION
                                        </h3>
                                        <p className="at-recruitment--text">
                                            Review the minimum qualification of the job. If qualify then apply on our website www.atulaey.com
                                        </p>
                                    </div>
                                    <img src={`${process.env.PUBLIC_URL}/images/flow-right.svg`} alt="" className="at-career-dots-right" />
                                </div>
                            </div>

                            <div className="at-career__recruitment-col col-md-12">
                                <div className="at-career__recritment--process">
                                    <img src={`${process.env.PUBLIC_URL}/images/flow-left.svg`} alt="" className="at-career-dots-left" />
                                    <div className="at-recruitment-img">
                                        <img src={`${process.env.PUBLIC_URL}/images/review.png`} alt="" />
                                    </div>
                                    <div className="at-recruitment--content">
                                        <h3 className="at-recruitment--heading">
                                            2 ) QUALIFICATION REVIEW
                                        </h3>
                                        <p className="at-recruitment--text">
                                            Our goal is to hire best talent from the country. Applications are reviewed and contacted by mail.
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div className="at-career__recruitment-col col-md-12">
                                <div className="at-career__recritment--process">
                                    <div className="at-recruitment-img">
                                        <img src={`${process.env.PUBLIC_URL}/images/exam.png`} alt="" />
                                    </div>
                                    <div className="at-recruitment--content">
                                        <h3 className="at-recruitment--heading">
                                            3 ) EXAMINATION
                                        </h3>
                                        <p className="at-recruitment--text">
                                            Depending upon the position , a exam is conducted to check the required skillset.
                                        </p>
                                    </div>
                                    <img src={`${process.env.PUBLIC_URL}/images/flow-right.svg`} alt="" className="at-career-dots-right" />
                                </div>
                            </div>

                            <div className="at-career__recruitment-col col-md-12">
                                <div className="at-career__recritment--process">
                                    <img src={`${process.env.PUBLIC_URL}/images/flow-left.svg`} alt="" className="at-career-dots-left" />
                                    <div className="at-recruitment-img">
                                        <img src={`${process.env.PUBLIC_URL}/images/interview.png`} alt="" />
                                    </div>
                                    <div className="at-recruitment--content">
                                        <h3 className="at-recruitment--heading">
                                            4 ) INTERVIEW
                                        </h3>
                                        <p className="at-recruitment--text">
                                            Qualified candidates are then checked by interview to know the best.
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div className="at-career__recruitment-col col-md-12">
                                <div className="at-career__recritment--process">
                                    <div className="at-recruitment-img">
                                        <img src={`${process.env.PUBLIC_URL}/images/JOB.png`} alt="" />
                                    </div>
                                    <div className="at-recruitment--content">
                                        <h3 className="at-recruitment--heading">
                                            5 ) JOB OFFER
                                        </h3>
                                        <p className="at-recruitment--text">
                                            The hiring manager will directly reach out to selected candidate through mail and call.
                                        </p>
                                    </div>
                                    <img src={`${process.env.PUBLIC_URL}/images/flow-right.svg`} alt="" className="at-career-dots-right" />
                                </div>
                            </div>

                            <div className="at-career__recruitment-col col-md-12">
                                <div className="at-career__recritment--process">
                                    <div className="at-recruitment-img">
                                        <img src={`${process.env.PUBLIC_URL}/images/onboarding.png`} alt="" />
                                    </div>
                                    <div className="at-recruitment--content">
                                        <h3 className="at-recruitment--heading">
                                            6 ) ONBOARDING
                                        </h3>
                                        <p className="at-recruitment--text">
                                            The onboarding process include required task to ensure you have an efficient start to your employement.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ContactForm />
            </div>
        </div>
    )
}

export default Career;