import React from 'react';
import Home from './screens/Home/Home'
import About from './screens/About/About'
import Service from './screens/Service/Service'
import Career from './screens/Career/Career'
import ContactUs from './screens/ContactUs/ContactUs'
import Header from './components/Header/Header'
import Footer from './components/Footer/Footer'
import ScrollToTop from './components/ScrollToTop/ScrollToTop'
import { BrowserRouter, Switch, Route } from 'react-router-dom'
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import './index.css'



const App = () => {
  return (
    <BrowserRouter>

      <div>
        <ScrollToTop />
        <Header />
        <Switch>
          <Route path="/" exact component={Home}></Route>
          <Route path="/about" exact component={About}></Route>
          <Route path="/career" exact component={Career}></Route>
          <Route path="/service" exact component={Service}></Route>
          <Route path="/contactus" exact component={ContactUs}></Route>
        </Switch>
        <Footer />
      </div>
    </BrowserRouter >
  );
}

export default App