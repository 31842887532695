import React from 'react'
import { store } from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';
import 'animate.css';

const NewsletterModal = (props) => {
    return (
        <>
            <p> {props.toastMessage} </p>
        </>
    )
}

export default NewsletterModal;