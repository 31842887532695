import React from 'react'
import './ContactUs.css'
import Form from './Form'

const ContactUs = () => {
    return (
        <div>
            <div id="at-contact-us">

                <div className="at-wrapper at-contact__banner-wrapper">
                    <div className="container at-contact__bg-container">
                        <div className="row at-contact-banner__row">
                            <div className="at-contact-banner__content col-lg-6">
                                <div className="at-contact__inner-content">
                                    <h2 className="at-contact__banner--heading">
                                        CONTACT US
                                    </h2>
                                    <h3 className="at-contact__banner--text">
                                        Want to grow with an organisation where we work as a team and share a bond as a family . We <span>welcome you to ATULAEY</span> , an organisation where dreams become reality .
                                    </h3>
                                </div>
                            </div>
                            <div className="at-contact-banner__img col-lg-6">
                                <img src={`${process.env.PUBLIC_URL}/images/contact-banner.png`} alt="" />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="at-wrapper at-contact-wrapper">
                    <div className="container at-contact-container">
                        <h2 className="at-contact--heading">
                            GET IN <span>TOUCH</span>
                        </h2>
                        <p className="at-contact--para">
                            For joining do let us know about you . We will be happy to know you . Please fill the form and we will reach out to you .
                        </p>
                    </div>
                </div>

                <Form />

                <div className="at-contact-image-section at-wrapper">
                    <img src={`${process.env.PUBLIC_URL}/images/img-contact-us.png`} alt="" />
                </div>
            </div>
        </div>
    )
}


export default ContactUs;