import React from 'react'
import './Home.css'

const Home = () => {
    return (
        <div>
            <div id="homepage">
                <div className="at-wrapper at-home__banner-wrapper">
                    <div className="at-home__bg-container">
                        <div className="at-banner__overlay"></div>
                        <div className="container at-home__inner-container">
                            <div className="row at-banner__row">
                                <div className="at-banner__content col-lg-6">
                                    <h2 className="at-home__banner--heading">
                                        Achieving Incredible
                                    </h2>
                                    <p className="at-home__banner--text">
                                        Atulaey products are focussed on providing digital platform for system
                                    </p>
                                    <ul className="at-home__banner-list">
                                        <li className="at-home__banner--list-items">
                                            <span>
                                                <img src={`${process.env.PUBLIC_URL}/images/tick-yellow.svg`} alt="check" />
                                            </span>
                                            <h5>
                                                Power of digitalisation
                                            </h5>
                                        </li>
                                        <li className="at-home__banner--list-items">
                                            <span>
                                                <img src={`${process.env.PUBLIC_URL}/images/tick-yellow.svg`} alt="check" />
                                            </span>
                                            <h5>
                                                Enabling transparency
                                            </h5>
                                        </li>
                                    </ul>
                                    <div className="at-button__wrapper">
                                        <a href="/About" className="at-button at-button--transparent">
                                            LEARN MORE
                                        </a>
                                        <a href="/ContactUs" className="at-button at-button--background">
                                            JOIN US
                                        </a>
                                    </div>
                                </div>
                                <div className="at-banner__img col-lg-6">
                                    <img src={`${process.env.PUBLIC_URL}/images/banner-vector.png`} alt="banner-vector-img" />
                                </div>
                            </div>
                            <div className="at-banner__overlay"></div>
                        </div>
                    </div>
                </div>

                <div className="at-wrapper at-home__product-wrapper">
                    <div className="at-home__product-container container">
                        <div className="at-product__pattern-heading">
                            <h1 className="at-home__product--heading">
                                We Create
                                <span> lovable </span>
                                products going from vision to launch
                            </h1>
                            <img src={`${process.env.PUBLIC_URL}/images/circle-gp-1.png`} alt="" />
                        </div>

                        <div className="row at-product__image-section">
                            <div className="col-lg-6 at-prod__image">
                                <img src={`${process.env.PUBLIC_URL}/images/Odriyo.png`} alt="Odriyo" />
                            </div>
                            <div className="col-lg-6 at-prod__about">
                                <div className="at-prod__overlay-bg">
                                    <h5>
                                        Odriyo
                                    </h5>
                                    <h3>
                                        Serving you the best lodging and fooding facilities
                                    </h3>
                                    <h4>
                                        Getting the facilities on finger tips has never been so easy . We provide the accessibility of all the service just by few clicks.
                                    </h4>
                                </div>
                                <img src={`${process.env.PUBLIC_URL}/images/circle-gp-3.png`} className="at-circle-2" alt="circle" />
                            </div>
                        </div>

                        {/* <div className="row at-product__image-section-alt">
                            <div className="col-lg-6 at-prod__about">
                                <div className="at-prod__overlay-bg">
                                    <h5>
                                        Ea commodo consequat
                                    </h5>
                                    <h3>
                                        Lorem ipsum dolor sit amet, consectetur
                                    </h3>
                                    <h4>
                                        Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
                                        commodo consequat.
                                    </h4>
                                </div>
                                <img src={`${process.env.PUBLIC_URL}/images/circle-gp-3.png`} className="at-circle-3" alt="circle" />
                            </div>
                            <div className="col-lg-6 at-prod__image">
                                <img src={`${process.env.PUBLIC_URL}/images/img2.png`} alt="" />
                            </div>
                        </div> */}
                    </div>
                </div>

                <div className="at-wrapper at-home__card-wrapper">
                    <div className="container at-home__card-container">
                        <h2 class="at-about__whyus--heading">
                            Why <span>Choose</span> Us ?
                        </h2>
                        <div className="row at-home__card-row">
                            <div className="at-whyus__card">
                                <img src={`${process.env.PUBLIC_URL}/images/lock.svg`} alt="lock" className="at-whyus--icon" />
                                <div className="at-card__inner-container">
                                    <h4 className="at-whyus--heading">
                                        SECURITY
                                    </h4>
                                    <p className="at-whyus--para">
                                        YOUR SECURITY , OUR PRIORITY
                                    </p>
                                    <h5 className="at-whyus--bodytext">
                                        Data security is something that can't be compromised. We at <strong>Atulaey</strong> believe that data security is the key for growing trust among us. So we keep things transparent .
                                    </h5>
                                </div>
                            </div>
                            <div className="at-whyus__card">
                                <img src={`${process.env.PUBLIC_URL}/images/time.png`} alt="lock" className="at-whyus--icon" />
                                <div className="at-card__inner-container">
                                    <h4 className="at-whyus--heading">
                                        ON TIME
                                    </h4>
                                    <p className="at-whyus--para">
                                        WE ARE NEVER LATE
                                    </p>
                                    <h5 className="at-whyus--bodytext">
                                        Good service is that which is always on time without any excuse . We at <strong>Atulaey</strong> are known for this. We follow the deadline strictly along with the quality work .
                                    </h5>
                                </div>
                            </div>
                            <div className="at-whyus__card">
                                <img src={`${process.env.PUBLIC_URL}/images/support.png`} alt="lock" className="at-whyus--icon" />
                                <div className="at-card__inner-container">
                                    <h4 className="at-whyus--heading">
                                        SUPPORT
                                    </h4>
                                    <p className="at-whyus--para">
                                        24 x 7 TECHNICAL SUPPORT
                                    </p>
                                    <h5 className="at-whyus--bodytext">
                                        Need help in Integrating , Deploying , Solving issues or  Customisation , We at <strong>Atulaey</strong> have a great support team who are available 24 * 7 to help you .
                                    </h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* <div className="at-wrapper at-home__our-team">
                    <div className="at-team-inner-wrap">
                        <div className="container at-home__our-team-container">
                            <div className="row at-team--row">
                                <div className="col-xl-5 at-team-desc">
                                    <h2 className="at-team--heading">
                                        Hi, We’re Atulaey
                                    </h2>
                                    <p className="at-team-description">
                                        At ATULAEY , we not only value our internal partnership with team , we also beleive in lasting long relationship with our customers for speedy and quality work . We build what you think . Converting the dream to the reality is our priority . We are here to deliver best everytime . We not only build client requirements but at the same we are coming up with new ideas soon making the world more digitalised and life much easier .
                                    </p>
                                    <a href="#" className="at-button at-button--background">
                                        MEET OUR TEAM
                                    </a>
                                </div>
                                <div className="col-xl-7 at-team-image">
                                    <div className="at-team-member__container">
                                        <div className="at-member at-member-1">
                                            <img src={`${process.env.PUBLIC_URL}/images/member-1.png`} alt="" />
                                        </div>
                                        <div className="at-member at-member-2">
                                            <img src={`${process.env.PUBLIC_URL}/images/member-2.png`} alt="" />
                                        </div>
                                        <div className="at-member at-member-3">
                                            <img src={`${process.env.PUBLIC_URL}/images/member-3.png`} alt="" />
                                        </div>
                                        <div className="at-member at-member-4">
                                            <img src={`${process.env.PUBLIC_URL}/images/member-4.png`} alt="" />
                                        </div>
                                        <div className="at-member at-member-5">
                                            <img src={`${process.env.PUBLIC_URL}/images/member-5.png`} alt="" />
                                        </div>
                                        <div className="at-member at-member-6">
                                            <img src={`${process.env.PUBLIC_URL}/images/member-6.png`} alt="" />
                                        </div><div className="at-member at-member-7">
                                            <img src={`${process.env.PUBLIC_URL}/images/member-7.png`} alt="" />
                                        </div>
                                        <div className="at-member at-member-8">
                                            <img src={`${process.env.PUBLIC_URL}/images/member-8.png`} alt="" />
                                        </div>
                                        <div className="at-member at-member-9">
                                            <img src={`${process.env.PUBLIC_URL}/images/member-9.png`} alt="" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}

                <div className="at-wrapper at-partner-cta-wrapper">
                    <div className="container at-partner__container">
                        <div className="at-partner-cta--section">
                            <div className="at-partner-cta--content">
                                <img src={`${process.env.PUBLIC_URL}/images/circle-pattern-1.png`} alt="" className="at-img__bl" />
                                <h2 className="at-partnet__cta--title">
                                    Join Our Team And Live <span>YOUR</span> Dream
                                </h2>
                                <a href="/Career" className="at-button at-button--background">
                                    PARTNER WITH US
                                </a>
                                <img src={`${process.env.PUBLIC_URL}/images/circle-pattern-2.png`} alt="" className="at-img__tr" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}


export default Home;
