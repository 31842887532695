import { Link } from "react-router-dom"
import './Footer.css'
import { useState } from 'react'
import axios from "axios";
import NewsletterModal from "./NewsletterModal"

const Footer = () => {
    const [email, setEmail] = useState("");
    const [toast, setToast] = useState("");
    const onSubmitHandler = () => {
        // call the api
        // send the data
        let data = { "emailId": email }
        axios.post("http://atul7v.us-east-1.elasticbeanstalk.com/subscriber/save", data)
            .then(res => {
                setToast(res.data);
            }).catch(err => {
                //handling error
                console.log(err);
            })
    }
    return (
        <div className="at-wrapper at-footer">
            <div className="at-footer-bg-pattern">
                <div className="at-footer--overlay"></div>
                <div className="container">
                    <div className="row at-footer-row1">
                        <div className="col-lg-4 at-footer-col1">
                            <img src={`${process.env.PUBLIC_URL}/images/logo-colored.svg`} alt="logo" className="at-footer--logo" />
                            <h4 className="at-footer__brand-subject">
                                Connect with us Socially and know whats happening at Atulaey.
                            </h4>
                            <p className="at-footer__subscribe-us--heading">
                                SUBSCRIBE US
                            </p>
                            <h5 className="at-footer__subscribe-us--text">
                                Sign up for our mailing list to get latest updates and offers
                            </h5>
                            <div className="at-footer--subscribe-form">
                                <input type="email" id="email" name="email" value={email} placeholder="xyz@gmail.com" onChange={(e) => setEmail(e.target.value)} />
                                <button onClick={onSubmitHandler}>
                                    <img src={`${process.env.PUBLIC_URL}/images/arrow-forward.svg`} alt="" />
                                </button>
                            </div>
                            {
                                (toast.length == 0) ? null : <NewsletterModal toastMessage={toast} />
                            }
                            <img src="" />
                        </div>
                        <div className="col-lg-6 at-footer-col2">
                            <p className="at-footer__subscribe-us--heading">
                                CONTACT US
                            </p>
                            <h4 className="at-footer__subscribe-us--text">
                                <a href="">
                                    I-99 , alpha 2 , Greater Noida , Uttar Pradesh , India
                                </a>
                            </h4>
                            <h4 className="at-footer__subscribe-us--text">
                                <a href="">
                                    hr@atulaey.com
                                </a>
                            </h4>
                            <h4 className="at-footer__subscribe-us--text">
                                <a href="">
                                    840-040-8888
                                </a>
                            </h4>
                        </div>
                        <div className="col-lg-2 at-footer-col3">
                            <p className="at-footer__subscribe-us--heading">
                                USEFUL LINKS
                            </p>
                            <h4 className="at-footer__subscribe-us--text">
                                <Link to={'/'} >
                                    Home
                                </Link>
                            </h4>
                            <h4 className="at-footer__subscribe-us--text">
                                <Link to={'/about'} >
                                    About
                                </Link>
                            </h4>
                            <h4 className="at-footer__subscribe-us--text">
                                <Link to={'/career'} >
                                    Career
                                </Link>
                            </h4>
                            <h4 className="at-footer__subscribe-us--text">
                                <Link to={'/services'} >
                                    Services
                                </Link>
                            </h4>
                            <h4 className="at-footer__subscribe-us--text">
                                <Link to={'/contactus'} >
                                    Contact Us
                                </Link>
                            </h4>
                        </div>
                    </div>
                    <div className="row at-footer-row2">
                        <div className="col-lg-6 at-footer-col1">
                            <h6 className="at-footer__copyright--text">
                                <a href="#">
                                    © 2020 All Rights Reserved. Design by Atulaey Inc.
                                </a>
                            </h6>
                        </div>
                        <div className="col-lg-2 at-footer-col2">
                            <h6 className="at-footer__terms--text">
                                <a href="#">
                                    Terms Of Use
                                </a>
                            </h6>
                        </div>
                        <div className="col-lg-4 at-footer-col3">
                            <h6 className="at-footer__privacy--text">
                                <a href="#">
                                    Privacy & Security Statement
                                </a>
                            </h6>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer;